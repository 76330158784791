import { useContactsContext } from "../contextapi/ContactsContextProvider.js";
import { Navigate, Outlet, useParams } from "react-router-dom";


export const WildcardProtectedRoute = ({ redirectPath = "/" }) => {
  return <Navigate to={redirectPath} replace />;
};

export const ReadOnlyProtectedRoute = ({ redirectPath = "/" }) => {
  const contactsContext = useContactsContext();
  if (contactsContext.user?.is_read_only) {
    if (!!contactsContext?.accountNameInfo?.searchedItem) {
      redirectPath = `/accounts?accountName=${encodeURIComponent(contactsContext?.accountNameInfo?.searchedItem)}`
    }
  }
  return contactsContext.user?.is_read_only ? <Navigate to={redirectPath} replace /> : <Outlet />;
}


export const EditProtectedRoute = ({ redirectPath = "/" }) => {
  const { account } = useParams();
  const contactsContext = useContactsContext();  
  if (!contactsContext?.accountInfo?.contacts || contactsContext?.accountInfo?.contacts?.length === 0 ) {
    redirectPath = `/accounts/${account}/contacts`
  }
  return !contactsContext?.accountInfo?.contacts || contactsContext?.accountInfo?.contacts?.length === 0 ? <Navigate to={redirectPath} replace /> : <Outlet />;
}