import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TdsTable,
  TdsTableColumn,
  TdsField,
  TdsCheck,
  TdsButton,
  TdsModal,
  TdsTooltip,
} from "@trv-tds/react";
import { ReactComponent as InfoCircle118 } from "@trv-ebus/tds-icons/dist/assets/icons/functional/svg/info-circle-1-18.svg";
import "../assets/stylesheets/createcontact.css";
import { contactTypesLearnMore } from "../helpers/contactTypesHelper.js";
import moment from "moment";
import { useContactsContext } from "../contextapi/ContactsContextProvider";
import { PatternFormat } from "react-number-format";

function ContactForm({
  handleSubmit,
  prefilledValues = {
    email: "",
    fst_nm: "",
    lst_nm: "",
    cust_cntc_cel_tel_nbr: "",
  },
  prefilledCheckBoxValues = [],
  isEditPage,
  lastModDate = {
    lst_mod_usr_full_nm: '',
    lst_mod_ts: ""
  },
}) {
  const navigate = useNavigate();
  const contactsContext = useContactsContext();

  const [textValue, setTextValue] = useState(prefilledValues);
  const [checkValue, setCheckValue] = useState(prefilledCheckBoxValues);
  const [formInputMsgAlertType, setFormInputMsgAlertType] = useState({
    email: "",
    fst_nm: "",
    lst_nm: "",
    policyNumber: "",
    contactType: "",
  });
  const [formInputMsgValue, setFormInputMsgValue] = useState({
    email: "",
    fst_nm: "",
    lst_nm: "",
    policyNumber: "",
    contactType: "",
  });

  function handleTextChange(event) {
    setTextValue({
      ...textValue,
      [event.target.name]: event.target.value,
    });
  }

  // Trim the input values
  const trimmedTextValue = {
    ...textValue,
    email: typeof textValue.email === "string" ? textValue.email.trim() : "",
    fst_nm: typeof textValue.fst_nm === "string" ? textValue.fst_nm.trim() : "",
    lst_nm: typeof textValue.lst_nm === "string" ? textValue.lst_nm.trim() : "",
    cust_cntc_cel_tel_nbr:
      typeof textValue.cust_cntc_cel_tel_nbr === "string"
        ? textValue.cust_cntc_cel_tel_nbr.trim()
        : "",
  };

  function handleCheckboxClick(event) {
    if (event.target.checked) {
      setCheckValue([...checkValue, event.target.value]);
    } else {
      setCheckValue(checkValue.filter((value) => value !== event.target.value));
    }
  }

  function handleCancelClick() {
    if (!contactsContext.accountInfo.contacts || contactsContext.accountInfo.contacts.length === 0) {
      contactsContext.updateAccountInfo({});
      
      if ( contactsContext.accountNameInfo.accounts ) {
          navigate(-1);
      } 

      navigate('/', { replace: true });
      return;
    }

    const resetContent = {
      email: "",
      fst_nm: "",
      lst_nm: "",
      cust_cntc_cel_tel_nbr: "",
    };
    setTextValue(resetContent);
    setCheckValue([]);
    navigate(-1);
  }

  const renderActionColumnHeader = (row, classes, attributes, style) => {
    style["font-size"] = "14px";
    style["font-weight"] = "bold";
    style["padding-top"] = "10px";
    style["padding-bottom"] = "10px";
    style["text-transform"] = "none";
  };

  const renderActionColumnBody = (row, classes, attributes, style) => {
    style["font-weight"] = "400px";
    style["padding-top"] = "30px";
    style["padding-bottom"] = "30px";
  };

  let lastModName =
    isEditPage &&
      lastModDate.lst_mod_usr_full_nm !== null &&
      lastModDate.lst_mod_usr_full_nm !== ""
      ? `by\u00A0${lastModDate.lst_mod_usr_full_nm.replace(" ", "\u00A0")}`
      : "";

  return (
    <div className="input-fields-container">
      <form
        onSubmit={(e) =>
          handleSubmit(
            e,
            trimmedTextValue,
            checkValue,
            setFormInputMsgAlertType,
            setFormInputMsgValue,
            formInputMsgAlertType
          ) && e.preventDefault()
        }
        data-testid="form"
      >
        <div className="form-text-field-inputs">
          <TdsField
            className="email-input"
            data-testid="email-address-field"
            label="Email Address"
            alert={formInputMsgAlertType.email}
            message={formInputMsgValue.email}
            message-aria-live="assertive"
          >
            <input
              data-testid="email-address-input"
              type="text"
              name="email"
              value={textValue.email}
              onChange={handleTextChange}
            />
          </TdsField>
          <TdsField
            data-testid="first-name-field"
            label="First Name"
            alert={formInputMsgAlertType.fst_nm}
            message={formInputMsgValue.fst_nm}
            message-aria-live="assertive"
          >
            <input
              data-testid="first-name-input"
              type="text"
              name="fst_nm"
              value={textValue.fst_nm}
              onChange={handleTextChange}
            />
          </TdsField>
          <TdsField
            data-testid="last-name-field"
            label="Last Name"
            alert={formInputMsgAlertType.lst_nm}
            message={formInputMsgValue.lst_nm}
            message-aria-live="assertive"
          >
            <input
              data-testid="last-name-input"
              type="text"
              name="lst_nm"
              value={textValue.lst_nm}
              onChange={handleTextChange}
            />
          </TdsField>
          <TdsField
            data-testid="phone-number-field"
            label="Phone Number"
            alert={formInputMsgAlertType.cust_cntc_cel_tel_nbr}
            message={formInputMsgValue.cust_cntc_cel_tel_nbr}
            message-aria-live="assertive"
          >
            <PatternFormat
              data-testid="phone-number-input"
              type="text"
              name="cust_cntc_cel_tel_nbr"
              format="(###) ###-####"
              value={textValue.cust_cntc_cel_tel_nbr}
              onValueChange={(values) => {
                setTextValue({
                  ...textValue,
                  cust_cntc_cel_tel_nbr: values.value,
                });
              }}
              valueIsNumericString={true}
            />
          </TdsField>
        </div>
        <div className="contact-types-check-boxes">
          <TdsField
            data-testid="contact-type-field"
            label="Contact Type(s)"
            alert={formInputMsgAlertType.contactType}
            message={formInputMsgValue.contactType}
            message-aria-live="assertive"
          >
            <div className="learn-more-modal-link">
              <p>
                <a
                  data-testid="learn-more-modal-link"
                  href="#learn-more-modal"
                  data-trigger-modal=""
                  aria-label="Opens modal for Learn More"
                >
                  Learn More
                </a>
              </p>
            </div>
            <TdsModal
              data-testid="learn-more-modal"
              id="learn-more-modal"
              size="large"
            >
              <div
                id="learn-more-medium"
                role="dialog"
                className="tds-modal tds-modal__dialog--medium"
                hidden=""
              >
                <div className="modal-title centered modal-borderline-under">
                  Contact Types
                </div>
                <TdsTable
                  className="learn-more-table"
                  id="learn-more-table"
                  rowItems={contactTypesLearnMore}
                  columnDividers="true"
                  bodyVertAlign="top"
                  fullWidth="true"
                >
                  <TdsTableColumn
                    item-path="contactType"
                    nowrap="true"
                    prepHeaderCell={renderActionColumnHeader}
                    prepBodyCell={renderActionColumnBody}
                  >
                    Contact Type
                  </TdsTableColumn>
                  <TdsTableColumn
                    item-path="definition"
                    prepHeaderCell={renderActionColumnHeader}
                    prepBodyCell={renderActionColumnBody}
                  >
                    Definition
                  </TdsTableColumn>
                  <TdsTableColumn
                    item-path="whatItMeans"
                    prepHeaderCell={renderActionColumnHeader}
                    prepBodyCell={renderActionColumnBody}
                  >
                    What It Means
                  </TdsTableColumn>
                </TdsTable>
              </div>
            </TdsModal>
            <div>
              <div className="contact-type-container">
                <TdsCheck
                  className="primary-contact-checkbox"
                  data-testid="pc-checkbox"
                  type="checkbox"
                  name="cust_cntc_typ_cd"
                  value="PC"
                  onTdsChange={handleCheckboxClick}
                  id="check-pc"
                  checked={
                    checkValue !== null
                      ? checkValue.find((contactType) => contactType === "PC")
                      : false
                  }
                >
                  Primary Contact
                </TdsCheck>
              </div>
              <div className="contact-type-container">
                <InfoCircle118
                  id="primary-contact-info"
                  aria-label="Primary contact information."
                  className="contact-type-width tds-icon-18 icon-link"
                />
                <TdsTooltip
                  className="home-page-text"
                  position="top-right"
                  aria-labelledby="primary-contact-info"
                >
                  <p className="tooltip-text">
                    Individual(s) at the company authorized to make<br></br>
                    decisions about their insurance coverage(s)
                  </p>
                </TdsTooltip>
              </div>
            </div>

            <div>
              <div className="contact-type-container">
                <TdsCheck
                  className="primary-contact-checkbox"
                  data-testid="as-checkbox"
                  type="checkbox"
                  name="cust_cntc_typ_cd"
                  value="AS"
                  onTdsChange={handleCheckboxClick}
                  id="check-as"
                  checked={
                    checkValue !== null
                      ? checkValue.find((contactType) => contactType === "AS")
                      : false
                  }
                >
                  Authorized Signer
                </TdsCheck>
              </div>
              <div className="contact-type-container">
                <InfoCircle118
                  id="authorized-signer-info"
                  aria-label="Authorized signer information."
                  className="contact-type-width tds-icon-18 icon-link"
                />
                <TdsTooltip
                  className="home-page-text"
                  position="top-right"
                  aria-labelledby="authorized-signer-info"
                >
                  <p className="tooltip-text">
                    The individual(s) at the company authorized to<br></br>
                    sign legal documents on behalf of the company
                  </p>
                </TdsTooltip>
              </div>
            </div>

            <div>
              <div className="contact-type-container">
                <TdsCheck
                  className="primary-contact-checkbox"
                  data-testid="rm-checkbox"
                  type="checkbox"
                  name="cust_cntc_typ_cd"
                  value="RM"
                  onTdsChange={handleCheckboxClick}
                  id="check-rm"
                  checked={
                    checkValue !== null
                      ? checkValue.find((contactType) => contactType === "RM")
                      : false
                  }
                >
                  Risk Management
                </TdsCheck>
              </div>
              <div className="contact-type-container">
                <InfoCircle118
                  id="risk-management-info"
                  aria-label="Risk management contact information"
                  className="contact-type-width tds-icon-18 icon-link"
                />
                <TdsTooltip
                  className="home-page-text"
                  position="top-right"
                  aria-labelledby="risk-management-info"
                >
                  <p className="tooltip-text">
                    Individual(s) with an interest in managing, or a<br />
                    requirement to manage, risks for the company
                  </p>
                </TdsTooltip>
              </div>
            </div>

            <div>
              <div className="contact-type-container">
                <TdsCheck
                  className="primary-contact-checkbox"
                  data-testid="cb-checkbox"
                  type="checkbox"
                  name="cust_cntc_typ_cd"
                  value="CB"
                  onTdsChange={handleCheckboxClick}
                  id="check-cb"
                  checked={
                    checkValue !== null
                      ? checkValue.find((contactType) => contactType === "CB")
                      : false
                  }
                >
                  Cyber Security
                </TdsCheck>
              </div>
              <div className="contact-type-container">
                <InfoCircle118
                  id="cyber-security-info"
                  aria-label="Cyber contact information"
                  className="contact-type-width tds-icon-18 icon-link"
                />
                <TdsTooltip
                  className="home-page-text"
                  position="top-right"
                  aria-labelledby="cyber-security-info"
                >
                  <p className="tooltip-text">
                    Individual(s) responsible for cyber security<br></br>
                    for the company
                  </p>
                </TdsTooltip>
              </div>
            </div>
          </TdsField>
        </div>
        {isEditPage && (
          <p className="lst-mod-bold">
            Last modified:{" "}
            <span className="lst-mod">
              {moment.utc(lastModDate.lst_mod_ts).format("llll")} UTC{" "}
              {lastModName}
            </span>
          </p>
        )}
        <div className="form-buttons line-top">
          <TdsButton
            data-testid="submit-button"
            variant="primary"
            className="submit-button"
            buttonType="submit"
          >
            Submit
          </TdsButton>
          <TdsButton
            data-testid="cancel-button"
            variant="secondary"
            className="cancel-button"
            onClick={handleCancelClick}
          >
            Cancel
          </TdsButton>
        </div>
      </form>
    </div>
  );
}

export default ContactForm;
