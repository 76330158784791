import React from "react";
import "./index.css";
import { createBrowserRouter, defer, RouterProvider, redirect, Outlet } from "react-router-dom";
import queryClient from "./queryClient";
import { LoginCallback } from "@okta/okta-react";
import Home from "./pages/Home";
import AccountNameSelect from "./pages/AccountNameSelect";
import SearchResults from "./pages/SearchResults";
import CreateContact from "./pages/CreateContact";
import EditContact from "./pages/EditContact";
import LoginError from "./components/LoginError/LoginError";
import { WildcardProtectedRoute, ReadOnlyProtectedRoute, EditProtectedRoute } from "./components/ProtectedRoute";
import { RequiredAuth } from "./SecureRoute";
import { passthroughToContactCollection } from "./services/PassthroughService";
import App from "./App";

const host = window.location.hostname.toLowerCase();
const IsExternal = host.includes('customercontacts');

let allowAccountNumberSearch = false;

const setAllowAccountNumberSearchLoader = async () => {
  setAllowAccountNumberSearch(true);
  return null;
}

const protectedLoader = async () => {
  if (IsExternal) {
    if (!allowAccountNumberSearch) {
      throw redirect('/');
    }
  }
  setAllowAccountNumberSearch(false)
  return null; // You can also return deferred data here if needed
};

const getAlertResponse = async (alertContent) => {
  return {
    data: {
      body: alertContent,
      invalidInput: true
    }
  }
}

const setAllowAccountNumberSearch = (toggle) => {
  allowAccountNumberSearch = toggle
  return null;
}


const getAccountNameResponse = async (accountName) => {
  const cachedData = queryClient.getQueryData(accountName);
  if (cachedData) { return cachedData; }

  let userId = JSON.parse(window.localStorage.getItem("okta-token-storage")).idToken.claims.preferred_username

  const requestType = !IsExternal ? "GET" : "POST";

  const route = !IsExternal ? `/api/v1/accountName?accountName=${encodeURIComponent(accountName)}` : "/api/v1/agentAccounts";

  const accountNameSearchRegex = /^((?:\s*\S){3}).{0,147}$/; // ; might change; currently accepts all queries with 3+ chars, but no spaces within the first 3 chars
  const accountNumberSearchRegex =
    /^(?=(?:[^A-Za-z]*[A-Za-z]){0,2}[^a-zA-Z]*$)[0-9a-zA-Z]{5,10}$/; // Allow letters for SAI search - make sure the string has less than 3 letters

  const isAccountNumber = accountNumberSearchRegex.test(String(accountName));
  const isAccountName = accountNameSearchRegex.test(String(accountName));

  const requestData = !IsExternal ? null : {
    accountNumber: isAccountNumber ? accountName : null,
    accountName: isAccountName && !isAccountNumber ? accountName : null,
    userId: userId
  };

  const alertContent = "We are sorry, there are no accounts matching your request. Please check and/or modify your search criteria.";
  if ((!isAccountName && !isAccountNumber) && IsExternal) {
    return getAlertResponse(alertContent)
  }
  else if (!isAccountName && !IsExternal) {
    return getAlertResponse(alertContent)
  }
  else {
    const response = passthroughToContactCollection(
      requestType,
      route,
      IsExternal,
      requestData,
      "N"
    );
    setAllowAccountNumberSearch(true);
    return response;
  }
}

const getAccountNumberResponse = async (accountNumber) => {
  const accountNumberSearchRegex =
    /^(?=(?:[^A-Za-z]*[A-Za-z]){0,2}[^a-zA-Z]*$)[0-9a-zA-Z]{5,10}$/; // Allow letters for SAI search - make sure the string has less than 3 letters
  const isAccountNumber = accountNumberSearchRegex.test(
    String(accountNumber)
  );

  if (!isAccountNumber) {
    const alertContent =
      "We are sorry, there are no accounts matching your request. Please check and/or modify your search criteria.";

    return getAlertResponse(alertContent)
  } else {
    const polarisSkip = allowAccountNumberSearch ? "Y" : "N"
    return passthroughToContactCollection(
      "GET",
      `/api/v1/accountNumber/${accountNumber}`,
      IsExternal,
      null,
      polarisSkip
    );
  }
}

export const router = createBrowserRouter([
  {
    element: <App isExternal={IsExternal} />,
    children: [
      {
        path: "/login/callback",
        element: (
          <LoginCallback errorComponent={LoginError} />
        )
      },
      {
        path: "/",
        element: <RequiredAuth />,
        children: [
          {
            index: true,
            element: <Home />
          },
          {
            path: "accounts",
            element: <AccountNameSelect />,
            loader: async ({ request }) => {
              const url = new URL(request.url);
              const accountName = url.searchParams.get('accountName');
              return defer({ promise: getAccountNameResponse(accountName) });
            }
          },
          {
            element: <Outlet />, // Simplified ProtectedRoute
            loader: protectedLoader, // Run the authentication check here
            children: [
              {
                path: "accounts/:account/contacts",
                element: <SearchResults />,
                loader: async ({ params }) => {
                  return defer({ contacts: getAccountNumberResponse(params.account) }); // should be called 'promise' instead of 'contacts'
                },
              },
            ]
          },
          {
            element: <ReadOnlyProtectedRoute />,
            children: [
              {
                path: "accounts/:account/contacts/add",
                element: <CreateContact />,
                loader: setAllowAccountNumberSearchLoader
              },
              {
                element: <EditProtectedRoute />,
                children: [
                  {
                    path: "accounts/:account/contacts/edit",
                    element: <EditContact />,
                    loader: setAllowAccountNumberSearchLoader
                  }
                ]
              }
            ]
          },
          {
            path: "*",
            element: <WildcardProtectedRoute />
          }
        ]
      }
    ]
  }
])

