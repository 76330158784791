const contactStatusesMap = {
    "VFD": "Verified",
    "EAI": "Email Address Invalid",
    "CNV": "Contact Needs Verification",
    "NCT": "No Contact Type",
    "CNN": "Contact Needs Name",
    "VFPD": "Verification Pending"
}

const contactNeedsAttentionStatuses = {
    "EAI": "Invalid email address. Please update email.",
    "CNV": "Contact Needs Verification.",
    "CNN": "Contact needs name. Please add name.",
}

const verifiedContact = {"VFD": "Verified"};



export { contactStatusesMap, contactNeedsAttentionStatuses, verifiedContact}