import { useEffect } from "react";
import { TdsCard, TdsCardContent } from "@trv-tds/react";
import "../assets/stylesheets/home.css";
import Search from "../components/Search.js";
import DownloadByContactType from "../components/DownloadByContactType";
import { useContactsContext } from "../contextapi/ContactsContextProvider";

function Home() {
  const contactsContext = useContactsContext();
  const userName = contactsContext.user.given_name
    ? contactsContext.user.given_name
    : "";
  const isUserDownloadWorthy = contactsContext.user.is_download_worthy;

  useEffect(() => {
    document.title = "Customer Contact Collection";
  }, []);

  return (
    <div className="tds-container">
      <div className="homepage-container">
        <div className="tds-col-md-auto home-container">
          <TdsCard
            className={
              isUserDownloadWorthy ? "primary-home-card-reduced" : "home-card"
            }
            size="full"
          >
            <div className="home-card-main-text">
              <TdsCardContent
                className={
                  isUserDownloadWorthy
                    ? "home-card-content-reduced"
                    : "home-card-content"
                }
              >
                <div className="action-card-text">
                  <h1 className="tds-h1 home-title">{userName}</h1>
                  <p className="tds-large home-msg-p home-page-text">
                    How would you like to search for contacts today?
                  </p>
                </div>
                <div className="action-card-container">
                  <Search fromHomePage={true} />
                </div>
              </TdsCardContent>
            </div>
          </TdsCard>
        </div>
        {isUserDownloadWorthy && (
          <div className="tds-col-md-auto download-container">
            <TdsCard className="additional-home-card-reduced" size="full">
              <div className="home-card-main-text">
                <TdsCardContent className="home-card-content-reduced">
                  <div className="action-card-text">
                    <h1 className="tds-h1 home-title">Contact Download</h1>
                    <p className="tds-large home-msg-p">
                      You can download contacts by contact type
                    </p>
                  </div>
                  <div className="action-card-container">
                    <DownloadByContactType />
                  </div>
                </TdsCardContent>
              </div>
            </TdsCard>
          </div>
        )}
      </div>
    </div>
  );
}

export default Home;
