import { TdsTable, TdsTableColumn, TdsTableStacked, TdsIconButton } from "@trv-tds/react";

import { ReactComponent as Plus124 } from "@trv-ebus/tds-icons/dist/assets/icons/functional/svg/plus-1-24.svg";
import { ReactComponent as Pencil118 } from "@trv-ebus/tds-icons/dist/assets/icons/functional/svg/pencil-1-18.svg";
import { ReactComponent as TrashBin118 } from "@trv-ebus/tds-icons/dist/assets/icons/functional/svg/trash-bin-1-18.svg";
import { ReactComponent as ExclamationCircle118 } from "@trv-ebus/tds-icons/dist/assets/icons/functional/svg/exclamation-circle-1-18.svg";
import { ReactComponent as CheckCircle118 } from "@trv-ebus/tds-icons/dist/assets/icons/functional/svg/check-circle-1-18.svg";
import { contactTypesMap } from "../helpers/contactTypesHelper.js";
import {
  contactStatusesMap,
  contactNeedsAttentionStatuses,
} from "../helpers/contactStatusesHelper.js";
import moment from "moment";
import "../assets/stylesheets/contactsDataTable.css";

const phoneFormatter = require("phone-formatter");

function ContactsDataTable({
  readOnlyUser,
  contacts,
  updateAndRenderNeedsAttention,
  handleEditContactClick,
  handleCreateContactClick,
  handleDeleteContactClick,
  contactNeedsAttention,
  handleDeleteSubmit,
  isVerifiedContact,
}) {
  const renderEdit = (row) => {
    return (
      <div>
        <TdsIconButton size="medium" label="Edit Contact" onClick={(e) => handleEditContactClick(row)}>
          <Pencil118 role="img" data-testid={`edit-button-${row.rowIndex}`} />
        </TdsIconButton>
      </div>
    );
  };

  const renderDelete = (row) => {
    return (
      <div>
        <TdsIconButton size="medium" label="Delete Contact" onClick={(e) => handleDeleteContactClick(row)}>
          <TrashBin118 role="img" data-testid={`delete-button-${row.rowIndex}`} />
        </TdsIconButton>
      </div>
    );
  };

  const renderActionColumnHeader = (row, classes, attributes, style) => {
    style["font-size"] = "13px";
    style["border-right"] = "none";
    style["width"] = "100px";
  };

  const renderContactTypes = (items) => {
    if (!items) {
      return;
    }

    return (
      <>
        {items.map((singleItem, i) => (
          <div key={i}>{contactTypesMap[singleItem]}</div>
        ))}
      </>
    );
  };

  const renderStatuses = (row) => {
    const statuses = row.item.cust_cntc_inf_vrfcn_cd;

    if (!statuses) {
      return;
    }

    return (
      <>
        {contactNeedsAttention(row) && (
          <ExclamationCircle118 className="tds-icon-18 needs-attention-icon" />
        )}
        {isVerifiedContact(row) && (
          <CheckCircle118
            data-testid="icon render"
            className="tds-icon-18 verified-contact-icon"
          />
        )}
        {statuses.map((status, i) => (
          <div key={i}>
            {contactNeedsAttentionStatuses[status] ? (
              <span className="status-bold">{contactStatusesMap[status]}</span>
            ) : (
              contactStatusesMap[status]
            )}
          </div>
        ))}
      </>
    );
  };

  const renderLastModDate = (lstModTs) => {
    return moment(new Date(lstModTs)).format("L");
  };

  const renderPhoneNumberFormat = (phoneNumber) => {
    return phoneNumber
      ? phoneFormatter.format(phoneNumber, "(NNN) NNN-NNNN")
      : "";
  };

  const renderColumnHeaders = (row, classes, attributes, style) => {
    style["font-size"] = "13px";
  };

  return (
    <div>
      <h3>Contact Information</h3>
      <TdsTable
        rowItems={contacts}
        columnDividers="true"
        bodyVertAlign="top"
        fullWidth="true"
        fixedCols={1}
        fixedLastCols={2}
      >
        <TdsTableColumn
          item-path="email"
          prepHeaderCell={renderColumnHeaders}
          prepBodyCell={updateAndRenderNeedsAttention}
        >
          Email Address
        </TdsTableColumn>
        <TdsTableColumn
          item-path="fst_nm"
          prepHeaderCell={renderColumnHeaders}
          prepBodyCell={updateAndRenderNeedsAttention}
        >
          First Name
        </TdsTableColumn>
        <TdsTableColumn
          item-path="lst_nm"
          prepHeaderCell={renderColumnHeaders}
          prepBodyCell={updateAndRenderNeedsAttention}
        >
          Last Name
        </TdsTableColumn>
        <TdsTableColumn
          item-path="cust_cntc_typ_cd"
          prepHeaderCell={renderColumnHeaders}
          prepBodyCell={updateAndRenderNeedsAttention}
          bodyCellRenderer={(row) =>
            renderContactTypes(row.item.cust_cntc_typ_cd)
          }
        >
          Contact Type(s)
        </TdsTableColumn>
        <TdsTableColumn
          item-path="cust_cntc_cel_tel_nbr"
          prepHeaderCell={renderColumnHeaders}
          prepBodyCell={updateAndRenderNeedsAttention}
          bodyCellRenderer={(row) =>
            renderPhoneNumberFormat(row.item.cust_cntc_cel_tel_nbr)
          }
        >
          Phone Number
        </TdsTableColumn>
        <TdsTableColumn
          item-path="lst_mod_ts"
          prepHeaderCell={renderColumnHeaders}
          prepBodyCell={updateAndRenderNeedsAttention}
          bodyCellRenderer={(row) => renderLastModDate(row.item.lst_mod_ts)}
        >
          Last Updated
        </TdsTableColumn>
        <TdsTableColumn
          item-path="cust_cntc_inf_vrfcn_cd"
          prepHeaderCell={renderColumnHeaders}
          prepBodyCell={updateAndRenderNeedsAttention}
          bodyCellRenderer={renderStatuses}
        >
          Status
        </TdsTableColumn>
        {!readOnlyUser && (
          <>
            <div className="tds-row">
              <TdsTableColumn
                item-path=""
                textAlign="center"
                prepHeaderCell={renderActionColumnHeader}
                prepBodyCell={updateAndRenderNeedsAttention}
                bodyCellRenderer={renderEdit}
                className="action-column action-column-edit"
              >
                Edit
              </TdsTableColumn>
              <div>
                <TdsTableColumn
                  item-path=""
                  textAlign="center"
                  prepHeaderCell={renderActionColumnHeader}
                  prepBodyCell={updateAndRenderNeedsAttention}
                  bodyCellRenderer={renderDelete}
                  className="action-column action-column-delete"
                >
                  Delete
                </TdsTableColumn>
              </div>
            </div>
          </>
        )}
        <div>
          <TdsTableStacked stackBelow="825"></TdsTableStacked>
        </div>
      </TdsTable>
      {!readOnlyUser && (
        <div
          className="create-contact-button"
          onClick={handleCreateContactClick}
        >
          <Plus124 className="tds-icon-24 icon-link" />{" "}
          <p role="link" tabIndex="0" className="icon-link-text">
            Add a new Contact
          </p>
        </div>
      )}
    </div>
  );
}

export default ContactsDataTable;
