import "@trv-tds/core/dist/css/foundations-with-grid.min.css";
import { TdsAlert } from "@trv-tds/react";
import React, { useState } from "react";
import DownloadByContactTypeHelper from "./DownloadByContactTypeHelper";
import { contactTypesMap } from "../helpers/contactTypesHelper";
import { passthroughToContactCollection } from "../services/PassthroughService";

function DownloadByContactType() {
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertContent, setFormAlertContent] = useState("");
  const [successAlertContactType, setSuccessAlertContactType] = useState("");
  const [alertVisible, setAlertVisible] = useState(false);
  const [buttonBusy, setButtonBusy] = useState(false);

  const submitDownloadRequest = async (contactType) => {
    setButtonBusy(true);
    let alertContent;
    let alertType;
    let alertVisible;

    try {
      // Request CSV from CCC API
      let downloadResponse = await passthroughToContactCollection(
        "GET",
        `/api/v1/contactType/${contactType.value}`,
        false
      );

      // Create object URL and download CSV for user
      const url = downloadResponse.data.url;
      const link = document.createElement("a");
      link.href = url;
      link.click();

      // Clean up resources for download
      link.remove();
      window.URL.revokeObjectURL(url);

      alertType = "success";
      alertContent = "Your download will begin shortly. Your download will contain the following items:";
      alertVisible = true;
      return;
    } catch (error) {
      alertType = "error";
      alertContent = "We are sorry, something went wrong. Please try again later. We apologize for the inconvenience and thank you for your patience.";
      alertVisible = true;
    } finally {
      if (alertVisible) {
        setFormAlertContent(alertContent);
        setFormAlertType(alertType);
        setAlertVisible(alertVisible);
        setSuccessAlertContactType(alertType !== "success" ? "" : `Contacts for ${contactTypesMap[contactType.value]}`)
        setButtonBusy(false);

        const enterActionId = window?.dtrum?.enterAction(`${alertContent}`, undefined, new Date().getTime(), window.location.href);
        window?.dtrum?.leaveAction(enterActionId, new Date().getTime());
      }
    }

  }

  return (
    <>
      {alertVisible && (
        <div className="tds-row centered alert-div">
          <div
            className="tds-col-sm-2 alert-style"
          ></div>
          <div
            className="tds-col-sm-8"
            aria-live="polite"
          >
            <TdsAlert
              data-testid="form-alert"
              type={formAlertType}
              inline="true"
              className="alert-message"
            >
              <span className="tds-sr-only">{formAlertType}</span>
              {formAlertContent}
              {successAlertContactType && <span className="contact-type-success-alert">{successAlertContactType}</span>}
            </TdsAlert>
          </div>

          <div className="tds-col-sm-auto"></div>
        </div>
      )}

      <DownloadByContactTypeHelper
        submitDownloadRequest={submitDownloadRequest}
        buttonBusy={buttonBusy}
      />
    </>
  );
}
export default DownloadByContactType;