import { ReactComponent as Magnifying124 } from "@trv-ebus/tds-icons/dist/assets/icons/functional/svg/magnifying-1-24.svg";
import "@trv-tds/core/dist/css/foundations-with-grid.min.css";
import Search from "./Search";
import {
  TdsGlobalHeader,
  TdsGlobalHeaderDropdown,
  TdsIconButton,
} from "@trv-tds/react";
import "../assets/stylesheets/navbar.css";
import { useContactsContext } from "../contextapi/ContactsContextProvider";
import useAuthUser from "../helpers/getUser";

function Navbar({ isExternal }) {
  const contactsContext = useContactsContext();
  useAuthUser(isExternal);

  const userNameGreeting = contactsContext.user.given_name
    ? "Hi, " + contactsContext.user.given_name
    : "";
  return (
    <div className="navbar-wrapper">
      <TdsGlobalHeader
        role="banner"
        homehref="/"
        homelabel="Home."
        product="Customer Contact Collection"
        centerbelow="930"
      >
        <div slot="actions" className="user-greeting">
          <span> {userNameGreeting} </span>
        </div>
        {contactsContext.user && (
          <>
            <div slot="actions" className="navbar-search">
              <span className="navbar-search-text">Search</span>
            </div>
            <TdsIconButton
              data-testid="navbar-search-icon-button"
              slot="actions"
              size="large"
              label="Search dropdown"
            >
              <Magnifying124 role="img" alt="Search dropdown" className="tds-icon-24" />
            </TdsIconButton>
            <TdsGlobalHeaderDropdown
              slot="actions"
              size="large"
              label="Search-Dropdown"
              title="Search-Dropdown"
              expanded={false}
            >
              {" "}
              <Search fromHomePage={false} />
            </TdsGlobalHeaderDropdown>
          </>
        )}
      </TdsGlobalHeader>
    </div>
  );
}

export default Navbar;
