import "@trv-tds/core/dist/css/foundations-with-grid.min.css";
import { TdsGlobalFooter } from "@trv-tds/react";
import { Outlet, useNavigate } from "react-router-dom";
import Navbar from "./components/Navbar";
import "./assets/stylesheets/home.css";
import { ContactsContextProvider } from "./contextapi/ContactsContextProvider";
import { Security } from "@okta/okta-react";
import { oktaAuthConfig, externalOktaAuthConfig } from "./config/oktaConfig";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";

function App({ isExternal }) {
    window.history.replaceState({}, document.title);

    const host = window.location.hostname.toLowerCase();
    const IsExternal = host.includes('customercontacts');
    let oktaConfig;
    if (IsExternal) {
        oktaConfig = externalOktaAuthConfig;
    } else {
        oktaConfig = oktaAuthConfig;
    }
    const oktaAuth = new OktaAuth(oktaConfig);
    const navigate = useNavigate();
    const originalUri = async (_oktaAuth, originalUri) => {
        navigate(toRelativeUrl(originalUri || "/", window.location.origin));
    };

    return (
        <div className="tds-app-wrapper">

            <Security oktaAuth={oktaAuth} restoreOriginalUri={originalUri}>
                <ContactsContextProvider>
                    <Navbar isExternal={isExternal} />
                    <Outlet />
                    <TdsGlobalFooter />
                </ContactsContextProvider>
            </Security>

        </div>
    );
}

export default App;
