import "@trv-tds/core/dist/css/foundations-with-grid.min.css";
import React, { useRef } from "react";
import { TdsField, TdsSelect, TdsButton } from "@trv-tds/react";
import { ReactComponent as Save118 } from "@trv-ebus/tds-icons/dist/assets/icons/functional/svg/save-1-18.svg";
import { contactTypesSelect } from "../helpers/contactTypesHelper";
import "../../src/assets/stylesheets/downloadByContactTypeHelper.css";

function DownloadByContactTypeHelper({ submitDownloadRequest, buttonBusy }) {
  const selectRef = useRef(null);

  return (
    <div className="home-download-container">
      <div className="tds-col-sm-2"></div>
      <div className="download-drop-down">
        <TdsField label="Download by contact type">
          <p className="custom-help-text">
            Select contact type below and choose download
          </p>
          <TdsSelect
            data-testid="dropdown"
            ref={selectRef}
            options={contactTypesSelect}
            optionName="name"
            optionValue="value"
            initialValue="ALL"
          ></TdsSelect>
        </TdsField>
      </div>

      <div className="download-contact-button">
        <TdsButton
          busy={buttonBusy}
          aria-label="download button"
          className="download-button"
          variant="primary"
          onClick={() => submitDownloadRequest(selectRef.current)}
        >
          Download&nbsp;&nbsp;
          <Save118 className="tds-icon-18" />
        </TdsButton>
      </div>
      <div className="tds-col-sm-2"></div>
    </div>
  );
}
export default DownloadByContactTypeHelper;
