import React from "react";
import { TdsAlert } from "@trv-tds/react";
import "../../assets/stylesheets/unauthorizedUser.css";

function LoginError() {
  return (
    <>
      <div className="alert-container-unatherorized-user">
        <div className="tds-row centered unauthorized-alerts">
          <div className="tds-col-sm-1"></div>
          <div className="tds-col-sm-10">
            <TdsAlert data-testid="notAuthorizedAlert" type="error" inline="true">
              <span>Sorry, you do not have access to this application.</span>
            </TdsAlert>
          </div>
        </div>
      </div>
      <div className="unauthorized-header-font unauthorized-center unauthorized-header ">
        <h1>Oops!</h1>
      </div>
      <div className="unauthorized-center">
        <p className="unauthorized-p1-style">
          It looks like you dont have access to this application
        </p>
        <p className="unauthorized-p2-style">
          To get access, please submit a <a href="https://idm.prodlb.travp.net/identityiq/home.jsf" target="_blank" rel="noreferrer">SailPoint</a> request to{" "}
          <br></br>
          <span className="unauthorized-p3-style">
            “BSI XMContactUI PROD”
          </span>
        </p>
      </div>
    </>
  );
}

export default LoginError;
