import "@trv-tds/core/dist/css/foundations-with-grid.min.css";
import { TdsAlert } from "@trv-tds/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useContactsContext } from "../contextapi/ContactsContextProvider";
import SearchHelper from "./SearchHelper";
import "../assets/stylesheets/home.css";

function Search({ fromHomePage }) {
  const contactsContext = useContactsContext();
  const [search, setSearch] = useState(``);
  const navigate = useNavigate();

  let alertContent;

  const updateSearch = (e) => {
    setSearch(e.target.value);
  };

  const submitSearch = async (e) => {
    const accountNumberSearchRegex =
      /^(?=(?:[^A-Za-z]*[A-Za-z]){0,2}[^a-zA-Z]*$)[0-9a-zA-Z]{5,10}$/; // Allow letters for SAI search - make sure the string has less than 3 letters
    const accountNameSearchRegex = /^((?:\s*\S){3}).{0,147}$/; // ; might change; currently accepts all queries with 3+ chars, but no spaces within the first 3 chars
    const isAccountNumber = accountNumberSearchRegex.test(
      String(e.target.value)
    );
    const isAccountName = accountNameSearchRegex.test(String(e.target.value));

    if (!isAccountNumber && !isAccountName) {
      alertContent =
        "We are sorry, there are no accounts matching your request. Please check and/or modify your search criteria.";

      contactsContext.handleAlertView(alertContent, "error", true, false);
      return;
    }
    try {
      if (!contactsContext.user.isExternal && isAccountNumber) {
        // make request to internal get contacts by account number search
        accountNumberHandler(e.target.value);
        return;
      } else if (!contactsContext.user.isExternal && isAccountName || contactsContext.user.isExternal) {
        // make request to internal get accounts search OR to external get acccounts search for agents
        accountNameHandler(e.target.value);
        return;
      }
    } catch (error) {
      alertContent =
        "We are sorry, something went wrong. Please try again later. We apologize for the inconvenience and thank you for your patience.";

      contactsContext.handleAlertView(alertContent, "error", true, false);
      return;
    }
  };

  const accountNumberHandler = (accountNumber) => {
    contactsContext.updateActionStartTime(new Date().getTime());

    contactsContext.clearAlertView();

    navigate(`accounts/${accountNumber}/contacts`);
  };

  const accountNameHandler = (accountName) => {
    contactsContext.updateAccountNameInfo({
      searchedItem: search
    });

    contactsContext.clearAlertView();

    navigate(`/accounts?accountName=${accountName}`);
  };

  return (
    <>
      {contactsContext.searchAlertVisible && (
        <div className="tds-row centered alert-div">
          <div
            className={
              fromHomePage ? "tds-col-sm-2 alert-style" : "tds-col-sm-1"
            }
          ></div>
          <div
            className={
              fromHomePage
                ? "tds-col-sm-8 account-search-container"
                : "tds-col-sm-10"
            }
            aria-live="polite"
          >
            <TdsAlert
              data-testid="form-alert"
              type={contactsContext.searchAlertType}
              inline="true"
              className="alert-message"
            >
              <span className="tds-sr-only">{contactsContext.searchAlertType}: </span>
              {contactsContext.searchAlertContent}
            </TdsAlert>
          </div>

          <div className="tds-col-sm-auto"></div>
        </div>
      )}

      <SearchHelper
        search={search}
        submitSearch={submitSearch}
        updateSearch={updateSearch}
        fromHomePage={fromHomePage}
      />
    </>
  );
}
export default Search;
