import React, { useState, useEffect, Suspense } from "react";
import "../assets/stylesheets/accountNameSelect.css";
import { useContactsContext } from "../contextapi/ContactsContextProvider.js";
import {
  TdsTablePagination,
  TdsTableColumn,
  TdsTable,
  TdsTableRowSelection,
  TdsAlert,
  TdsLoadingSpinner,
} from "@trv-tds/react";
import { useNavigate, useLocation, useLoaderData, useSearchParams, Await } from "react-router-dom";
import queryClient from "../queryClient.js";
import Home from "./Home.js";
 
function AccountNameSelect() {
  const loaderData = useLoaderData();
  const [searchParams] = useSearchParams();
  const searchedItem = searchParams.get("accountName");
  const navigate = useNavigate();
  const { state } = useLocation();
  const [moreThanTenContentResults, setMoreThanTenContentResults] = useState(state?.moreThanTenContentResults);
  const [rowSelected, setRowSelected] = useState([]);
 
  const contactsContext = useContactsContext();
  let alertContent;
 
  const accountNameDisplay = (row, classes, attributes, style) => {
    style["padding-top"] = "15px";
    style["padding-bottom"] = "15px";
    style["text-transform"] = "uppercase";
    style["border-bottom"] = "0.0625rem solid rgb(218, 219, 219)";
    style["border-top"] = "none";
  };
 
  const accountNameSelected = async (rowItem) => {
    setRowSelected([rowItem]);
 
    try {
 
      contactsContext.updateAccountInfo({searchedItem: rowItem.accountNumber})
 
      navigate(`/accounts/${rowItem.accountNumber}/contacts`);
      contactsContext.clearAlertView();
      return;
    } catch (error) {
      alertContent =
        error.response?.data.statusCode === 404
          ? "We are sorry, there are no accounts matching your request. Please check and/or modify your search criteria."
          : "We are sorry, something went wrong. Please try again later. We apologize for the inconvenience and thank you for your patience.";
 
      contactsContext.handleAlertView(alertContent, "error", true);
    }
  };
 
  const selectAccountName = (row, index) => {
    return (
      <div
        className="account-name-row"
        tabIndex={0}
        role="link"
        data-testid={`contacts-results-${index}`}
        onClick={(e) => accountNameSelected(row)}
      >
        {row.accountNameCityState}
      </div>
    );
  };
 
  const accountNameHeader = (row, classes, attributes, style) => {
    style["border-bottom"] = "0.0625rem solid rgb(218, 219, 219)";
  };
 
  useEffect(() => {
    document.title = `${searchedItem} | Customer Contact Collection`;
    loaderData.promise.then((promise) => {
      const { data } = promise;
 
      if(data.invalidInput) {
        contactsContext.handleAlertView(data.body, "error", true)
        navigate("/", { replace: true });
        return;
      }
 
      if (data.statusCode === 200) {
        if (data.body.accounts.length > 10) {
          setMoreThanTenContentResults(true)
        }
      } else if (data.statusCode === 404) {
        contactsContext.handleAlertView("We are sorry, there are no accounts matching your request. Please check and/or modify your search criteria.", "error", true)
        navigate("/", { replace: true });
      } else if (data.statusCode === 400) {
        contactsContext.handleAlertView("There are too many results to display. Please refine your search by adding additional characters to the Account Name.", "error", true)
        navigate("/", { replace: true });
      } else {
        contactsContext.handleAlertView("We are sorry, something went wrong. Please try again later. We apologize for the inconvenience and thank you for your patience.", "error", true)
        navigate("/", { replace: true });
      }
 
      data.body?.accounts?.forEach((info) => {
        let accountNameCityState = `${info.accountName} - ${info.accountCityName}, ${info.accountStateCode} `;
        info.accountNameCityState = accountNameCityState;
      });
 
      contactsContext.updateAccountNameInfo({
        searchedItem: searchedItem,
        ...data.body,
      });
 
      const cachedData = queryClient.getQueryData(searchedItem);
      if (!cachedData) { return queryClient.setQueryData(searchedItem, promise); }
    });
  }, []);
 
  return (
    <Suspense fallback={<TdsLoadingSpinner centered overlay />}>
      <Await resolve={loaderData.promise} errorElement={<Home/>}>
        {(loadedAccounts) => {
          return (
            <div className="tds-container">
              {moreThanTenContentResults && (
                <TdsAlert data-testid="form-alert" type="info" dismissible={true}>
                  <p>
                    The displayed items are the closest results for{" "}
                    <b className="important-text">
                      "{searchedItem}"
                    </b>
                    . Please start a new search to shorten this list.
                  </p>
                </TdsAlert>
              )}
              {contactsContext.searchAlertVisible && (
                <TdsAlert
                  data-testid="form-alert"
                  type={contactsContext.searchAlertType}
                  dismissible={true}
                >
                  {" "}
                  {contactsContext.searchAlertContent}{" "}
                </TdsAlert>
              )}
              <h1 className="header">Please select the account below</h1>
              <span className="caption-text">
                {loadedAccounts.data.body.accounts?.length} results for{" "}
                <b>"{searchedItem}"</b>
              </span>
              <TdsTable
                rowItems={loadedAccounts.data.body.accounts}
                full-width
                columnDividers="false"
                headerDividers="false"
              >
                <TdsTableColumn
                  row-selection
                  prepHeaderCell={accountNameHeader}
                  prepBodyCell={accountNameDisplay}
                ></TdsTableColumn>
                <TdsTableColumn
                  item-path="accountNameCityState"
                  prepHeaderCell={accountNameHeader}
                  prepBodyCell={accountNameDisplay}
                  bodyCellRenderer={(row) => selectAccountName(row.item, row.rowIndex)}
                ></TdsTableColumn>
                <TdsTableRowSelection
                  selectedRows={rowSelected}
                  singleSelect={true}
                  onTdsRowSelection={(e) => accountNameSelected(e.detail.rowItem)}
                ></TdsTableRowSelection>
                {moreThanTenContentResults && (
                  <TdsTablePagination
                    pageSizeOptions={[10, 20, 50, "All"]}
                  ></TdsTablePagination>
                )}
              </TdsTable>
            </div>
          );
        }}
      </Await>
    </Suspense >
  );
}
 
export default AccountNameSelect;