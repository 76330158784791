import axios from "axios";
import { v4 as uuidv4 } from "uuid";


const passthroughToContactCollection = async (
  method,
  path,
  IsExternal,
  data = null,
  polarisSkip = "N",
  addedHeaders = {}
) => {
  const request_headers = {
    Authorization: `Bearer ${JSON.parse(window.localStorage.getItem("okta-token-storage")).accessToken
      .accessToken
      }`,
    "polaris-skip": polarisSkip,
    "correlation-id": window.localStorage.getItem("correlation-id") == null ? uuidv4() : window.localStorage.getItem("correlation-id"),
    ...addedHeaders,
  };

  const userInfo = JSON.parse(window.localStorage.getItem("okta-token-storage")).idToken.claims;

  const userIdentifiers = JSON.parse(window.localStorage.getItem('user-identifiers')) == null ? {
    "user-shortname": IsExternal ? "" : userInfo.shortname,
    "user-ntic": IsExternal ? "" : userInfo.NTIC,
    "user-id": IsExternal ? userInfo.preferred_username : ""
  } : JSON.parse(window.localStorage.getItem('user-identifiers'))

  const merged_headers = {
    ...userIdentifiers,
    ...request_headers,
  };
 
  const request_body = {
    request: {
      method: method,
      path: path,
      data: data,
    },
  };

  const response = await axios.post(
    `/api/sys/v1/passthrough/contact-collection`,
    request_body,
    {
      headers: merged_headers,
    }
  );

  return response;
};

export { passthroughToContactCollection };
