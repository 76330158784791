import React, { useState, useEffect } from "react";
import { useContactsContext } from "../contextapi/ContactsContextProvider.js";
import { useNavigate, useLocation } from "react-router-dom";
import {
  TdsCard,
  TdsCardContent,
  TdsAlert,
  TdsLoadingSpinner,
} from "@trv-tds/react";
import "../assets/stylesheets/createcontact.css";
import { contactFormAlertValidation } from "../helpers/formValidationsHelper";
import { contactNeedsAttentionStatuses } from "../helpers/contactStatusesHelper.js";
import ContactForm from "../components/ContactForm.js";
import { passthroughToContactCollection } from "../services/PassthroughService";

function EditContact() {
  const contactsContext = useContactsContext();
  const navigate = useNavigate();

  const [formAlertContent, setFormAlertContent] = useState("");
  const [formAlertType, setFormAlertType] = useState("");
  const [alertVisible, setAlertVisible] = useState(false);
  const [loadSpinnerVisible, setLoadSpinnerVisible] = useState(false);

  const { state } = useLocation();

  const { contactId } = state ?? "";
  const contactToEdit = contactsContext?.accountInfo?.contacts?.find(
    (contact) => contact["bsi_cust_id"] === contactId
  );

  const prefilledValues = {
    email: contactToEdit?.["email"] ?? "",
    fst_nm: contactToEdit?.["fst_nm"] ?? "",
    lst_nm: contactToEdit?.["lst_nm"] ?? "",
    cust_cntc_cel_tel_nbr: contactToEdit?.["cust_cntc_cel_tel_nbr"] ?? ""
  };
  const prefilledCheckBoxValues = contactToEdit?.["cust_cntc_typ_cd"] ?? [];

  let alertContent;

  const prefillInputFieldMessages = () => {
    const contactStatuses = contactToEdit?.cust_cntc_inf_vrfcn_cd ?? "";
    if (!contactStatuses) {
      return;
    }
    let statusesFormAlerts = [];

    // email address is invalid
    contactStatuses.forEach((status) => {
      if (contactNeedsAttentionStatuses[status]) {
        statusesFormAlerts = [
          ...statusesFormAlerts,
          {
            statusFormAlertType: "warning",
            statusFormContent: contactNeedsAttentionStatuses[status],
          },
        ];
      }
    });

    return statusesFormAlerts;
  };

  const renderFormAlerts = () => {
    const statusesFormAlerts = prefillInputFieldMessages();
    if (!statusesFormAlerts) {
      return;
    }

    return statusesFormAlerts.map((formAlert, i) => {
      return (
        <TdsAlert
          data-testid="form-alert"
          type={formAlert.statusFormAlertType}
          dismissible={true}
          key={i}
        >
          {" "}
          {formAlert.statusFormContent}{" "}
        </TdsAlert>
      );
    });
  };

  const handleResponseError = () => {
    alertContent =
      "We are sorry, something went wrong. Please try again later. We apologize for the inconvenience and thank you for your patience.";

    setFormAlertType("error");
    setFormAlertContent(alertContent);
    setAlertVisible(true);
  };

  async function handleSubmit(
    event,
    textValue,
    checkValue,
    setFormInputMsgAlertType,
    setFormInputMsgValue
  ) {
    event.preventDefault();
    const validationErrors = contactFormAlertValidation(textValue, checkValue);

    if (validationErrors.formAlert.isFormAlertVisible) {
      setFormInputMsgAlertType(validationErrors.formInputMsgAlertType);
      setFormInputMsgValue(validationErrors.formInputMsgValue);
      setFormAlertContent(validationErrors.formAlert.formAlertContent);
      setFormAlertType(validationErrors.formAlert.formAlertType);
      setAlertVisible(validationErrors.formAlert.isFormAlertVisible);
      alertContent = validationErrors.formAlert.formAlertContent;
      return;
    }

    try {
      const updatedContacts = [...contactsContext.accountInfo.contacts];
      const contactToUpdate = updatedContacts.find(
        (contact) => contact["bsi_cust_id"] === contactId
      );

      const editContactRequest = {
        acct_nbr: contactsContext.accountInfo.acct_nbr,
        contact: {
          fst_nm: textValue.fst_nm,
          lst_nm: textValue.lst_nm,
          cust_cntc_cel_tel_nbr: textValue.cust_cntc_cel_tel_nbr
            ? textValue.cust_cntc_cel_tel_nbr
            : "",
          email: textValue.email,
          cust_cntc_typ_cd: checkValue,
          mod_datasrc_nm: "CUI",
          cust_cntc_inf_vrfcn_cd: contactToUpdate.cust_cntc_inf_vrfcn_cd,
          lst_mod_usr_full_nm: !!contactsContext?.user.name ? contactsContext.user.name : "",
        },
      };

      setLoadSpinnerVisible(true);

      const editResponse = await passthroughToContactCollection(
        "PUT",
        `/api/v1/contact/${contactToUpdate.bsi_cust_id}`,
        contactsContext.user.isExternal,
        editContactRequest
        
      );

      const { data } = editResponse;

      if (data?.statusCode === 200 || data?.statusCode === 201) {
        contactToUpdate["email"] = data.body.email;
        contactToUpdate["fst_nm"] = data.body.fst_nm;
        contactToUpdate["lst_nm"] = data.body.lst_nm;
        contactToUpdate["cust_cntc_cel_tel_nbr"] =
          data.body.cust_cntc_cel_tel_nbr;
        contactToUpdate["cust_cntc_typ_cd"] = data.body.cust_cntc_typ_cd;
        contactToUpdate["cust_cntc_inf_vrfcn_cd"] =
          data.body.cust_cntc_inf_vrfcn_cd;
        contactToUpdate["lst_mod_usr_full_nm"] = data.body.lst_mod_usr_full_nm;
        contactToUpdate["mod_datasrc_nm"] = data.body.mod_datasrc_nm;
        contactToUpdate["lst_mod_ts"] = data.body.lst_mod_ts;

        contactsContext.updateAccountInfo({
          ...contactsContext?.accountInfo,
          contacts: updatedContacts,
        });

        const enterActionId = window?.dtrum?.enterAction(
          "Editing Contact",
          undefined,
          contactsContext.actionStartTime,
          window.location.href
        );
        window?.dtrum?.leaveAction(enterActionId, new Date().getTime());

        navigate(`/accounts/${contactsContext.accountInfo.acct_nbr}/contacts`, {
          replace: true,
          state: { successMessage: "Contact has been updated." },
        });
      } else if (
        data?.statusCode === 400 &&
        data?.body.errorText === "Data Error - Duplicate contact exists"
      ) {
        alertContent = "Contact already exists. Unable to add.";

        setFormAlertType("error");
        setFormAlertContent(alertContent);
        setAlertVisible(true);
      } else {
        handleResponseError();
      }

      return;
    } catch (err) {
      handleResponseError();
      return;
    } finally {
      const enterActionId = window?.dtrum?.enterAction(
        `${validationErrors.formAlert.formAlertContent}`,
        undefined,
        new Date().getTime(),
        window.location.href
      );
      window?.dtrum?.leaveAction(enterActionId, new Date().getTime());
      setLoadSpinnerVisible(false);
    }
  }

  useEffect(() => {
    document.title = `Edit Contact | ${contactsContext.accountInfo.acct_nbr} | Customer Contact Collection`;
  }, []);

  return (
    <div className="tds-container">
      <div className="alert-container">
        {alertVisible && (
          <TdsAlert
            className="form-alert"
            data-testid="form-alert"
            type={formAlertType}
            dismissible={true}
          >
            {" "}
            {formAlertContent}{" "}
          </TdsAlert>
        )}
        {!alertVisible && renderFormAlerts()}
      </div>
      <div className="tds-row search-card-row">
        <TdsCard className="add-contact-card" size="full">
          <div className="add-contact-card-main-text">
            <TdsCardContent className="add-contact-card-content">
              <div className="add-contact-card-search-text">
                <h1 className="tds-h1 add-contact-title">
                  Update Contact Information
                </h1>
                <h2 className="tds-h3 add-contact-title">
                  Account Number: {contactsContext.accountInfo.acct_nbr} <br />{" "}
                  <span className="add-account-name-title">
                    {contactsContext.accountInfo.acct_nm}
                  </span>
                </h2>
              </div>
              <ContactForm
                handleSubmit={handleSubmit}
                prefilledValues={prefilledValues}
                prefilledCheckBoxValues={prefilledCheckBoxValues}
                isEditPage={true}
                lastModDate={contactToEdit}
              />
            </TdsCardContent>
          </div>
        </TdsCard>
      </div>

      {loadSpinnerVisible && (
        <TdsLoadingSpinner
          size="x-large"
          label-displayed
          overlay
          centered
        ></TdsLoadingSpinner>
      )}
    </div>
  );
}

export default EditContact;
