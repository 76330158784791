import "@trv-tds/core/dist/css/foundations-with-grid.min.css";
import { TdsSearchBox, TdsTooltip } from "@trv-tds/react";
import "../assets/stylesheets/navbar.css";
import { ReactComponent as InfoCircle118 } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/info-circle-1-18.svg'

function SearchHelper({ search, submitSearch, updateSearch, fromHomePage }) {
  if (fromHomePage) {
    return (
      <>
        <div className="tds-row search-box-row">
          <div className="tds-col-sm-2"></div>

          <div className="tds-col-sm-8">
            <span data-testid="search-accountname-accountnumber">
              Search by Account Name or Account Number &nbsp;
              <InfoCircle118 aria-label="Additional search information." role="tooltip" tabIndex="0" className="tooltip-helper-text tds-icon-18 icon-link" />
              <TdsTooltip className="home-page-text" role="tooltip" position="top" aria-labelledby="search-help">
                <span id="search-help">
                  Search by Account Name - need at least 3 characters.<br></br>
                  Or, use the Travelers account number.
                </span>
              </TdsTooltip>
            </span>
            <TdsSearchBox
              className="search-helper-text"
              data-testid="search-with-displayed-label"
              label="Search by Account Name or Account Number"
              hideLabel="true"
              value={search}
              onTdsSearched={submitSearch}
              onTdsSearchInputChange={updateSearch}
              enableClear={true}
            ></TdsSearchBox>
          </div>

          <div className="tds-col-sm-2" ></div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <span>Search by Account Name or Account Number</span>
        <TdsSearchBox
          className="search-helper-text"
          data-testid="search-with-navbar-displayed-label"
          label="Search by Account Name or Account Number"
          value={search}
          hideLabel="true"
          onTdsSearched={submitSearch}
          onTdsSearchInputChange={updateSearch}
          enableClear={true}
        ></TdsSearchBox>
      </>
    );
  }
}
export default SearchHelper;
