import { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useContactsContext } from "../contextapi/ContactsContextProvider";
import { useCookies } from "react-cookie";
import { v4 as uuidv4 } from "uuid";

const useAuthUser = (isExternal) => {
  const contactsContext = useContactsContext();
  const { oktaAuth, authState } = useOktaAuth();
  const [, setCookie] = useCookies(["ntic", "shortname", "correlationid"]);

  useEffect(() => {
    const getUser = async () => {
      try {
        if (!contactsContext.user) {
          const accessToken = authState?.accessToken?.accessToken;
          const res = await oktaAuth.getUser();

          res.accessToken = accessToken;

          let correlationId = uuidv4();
          setCookie("ntic", res.NTIC, { path: "/" });
          setCookie("shortname", res.shortname, { path: "/" });
          setCookie("correlationid", correlationId, { path: "/" });
          setCookie("isExternal", isExternal, { path: "/" });

          const isUserReadOnly = res.groups.filter((group) => group.includes("Read Only")).length === 1 && res.groups.filter((group) => !group.includes("Read Only") && !group.includes("Download Only")).length === 0;
          const isUserDownloadWorthy = res.groups.filter((group) => group.includes("Download Only")).length === 1;

          res.is_read_only = isUserReadOnly;
          res.is_download_worthy = isUserDownloadWorthy;
          res.isExternal = isExternal;

          res.user_identifiers = isExternal ? {
            "user-shortname": "",
            "user-ntic": "",
            "user-id": res.preferred_username,
          } : {
            "user-shortname": res.shortname,
            "user-ntic": res.NTIC,
            "user-id": ""
          };

          window.localStorage.setItem('correlation-id', correlationId)
          window.localStorage.setItem('user-identifiers', JSON.stringify(res.user_identifiers))

          contactsContext.updateUser(res);
          contactsContext.updateCorrelationId(correlationId);
        }
      } catch (error) {
        return error;
      }
    };

    !contactsContext.user && authState?.isAuthenticated && getUser();
  }, [authState, oktaAuth, contactsContext, setCookie]);
  return contactsContext.user;
};

export default useAuthUser;