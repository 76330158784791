export const oktaAuthConfig = {
  issuer: process.env.REACT_APP_OKTA_BASE_URL,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: process.env.REACT_APP_OKTA_REDIRECT_URI,
  pkce: true,
  scopes: ['openid', 'profile', 'email', 'groups'],
  tokenManager: {
    autoRenew: true
  }
};

export const externalOktaAuthConfig = {
  issuer: process.env.REACT_APP_EXTERNAL_OKTA_BASE_URL,
  clientId: process.env.REACT_APP_EXTERNAL_OKTA_CLIENT_ID,
  redirectUri: process.env.REACT_APP_EXTERNAL_OKTA_REDIRECT_URI,
  pkce: true,
  scopes: ['openid', 'profile', 'email', 'groups'],
  tokenManager: {
    autoRenew: true
  }
};
