const contactFormAlertMessages = {
  email: "Please enter a valid email.",
  fst_nm: "Please enter a valid name.",
  lst_nm: "Please enter a valid name.",
  cust_cntc_cel_tel_nbr: "Please enter a valid phone number.",
  contactType: "Please select one or more contact types.",
};

const contactFormAlertValidation = (textValue, checkValue) => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const nameRegex = /^(?=.{0,50}$).*$/;
  const cust_cntc_cel_tel_nbrRegex = /^[0-9]{3}[0-9]{3}[0-9]{4}$/;

  let validations = {
    formInputMsgAlertType: {
      email: "",
      fst_nm: "",
      lst_nm: "",
      policyNumber: "",
      contactType: "",
    },
    formInputMsgValue: {
      email: "",
      fst_nm: "",
      lst_nm: "",
      policyNumber: "",
      contactType: "",
    },
    formAlert: {
      formAlertContent: "",
      formAlertType: "",
      isFormAlertVisible: false,
    },
  };

  const formInputValidationFails = [];
  const { formInputMsgAlertType, formInputMsgValue } = validations;

  if (
    !emailRegex.test(String(textValue.email).toLowerCase()) ||
    String(textValue.email) === ""
  ) {
    formInputMsgAlertType.email = "error";
    formInputMsgValue.email = "Please enter a valid email.";
    formInputValidationFails.push("email");
  }

  if (!nameRegex.test(String(textValue.fst_nm).toLowerCase())) {
    formInputMsgAlertType.fst_nm = "error";
    formInputMsgValue.fst_nm = "Please enter a valid first name.";
    formInputValidationFails.push("fst_nm");
  }

  if (!nameRegex.test(String(textValue.lst_nm).toLowerCase())) {
    formInputMsgAlertType.lst_nm = "error";
    formInputMsgValue.lst_nm = "Please enter a valid last name.";
    formInputValidationFails.push("lst_nm");
  }

  if (
    !cust_cntc_cel_tel_nbrRegex.test(
      String(textValue.cust_cntc_cel_tel_nbr).toLowerCase()
    ) &&
    textValue.cust_cntc_cel_tel_nbr.trim() !== ""
  ) {
    formInputMsgAlertType.cust_cntc_cel_tel_nbr = "error";
    formInputMsgValue.cust_cntc_cel_tel_nbr =
      "Please enter a valid phone number.";
    formInputValidationFails.push("cust_cntc_cel_tel_nbr");
  }

  if (!checkValue.length) {
    formInputMsgAlertType.contactType = "error";
    formInputMsgValue.contactType = "Please select one or more contact types.";
    formInputValidationFails.push("contactType");
  }

  if (formInputValidationFails.length > 1) {
    validations.formAlert = {
      formAlertContent:
        "We found some errors. Please review the form and make corrections.",
      formAlertType: "error",
      isFormAlertVisible: true,
    };
  } else if (formInputValidationFails.length === 1) {
    validations.formAlert = {
      formAlertContent: contactFormAlertMessages[formInputValidationFails[0]],
      formAlertType: "error",
      isFormAlertVisible: true,
    };
  }

  return validations;
};

export { contactFormAlertValidation };
