import React, { useEffect } from "react";
import { TdsLoadingSpinner } from "@trv-tds/react";
import { useOktaAuth } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { Outlet } from "react-router-dom";

export const RequiredAuth = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const authStateCheck = !!authState;
  const authenticatedCheck = authState?.isAuthenticated;

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin
      );
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
  }, [oktaAuth, authStateCheck, authenticatedCheck, authState]);

  if (!authState || !authState?.isAuthenticated) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: '50vh'
        }}
      >
        <TdsLoadingSpinner data-testid="LoadingSpinner" />
      </div>
    );
  }
  return <Outlet />;
};
